.toolbar {
    background-color:white; 
    height: 100%;
}

.logo {
   margin-right: 5px;
   vertical-align: middle;
   height:50px;
   cursor: pointer;
}

.logo-observatorio {
    margin-left: 5px;
    margin-right: 5px;
    vertical-align: middle; 
    height:30px;
    border-radius: 50%;
}
@media screen and (min-height: 881px) {
  .textoProyecto{
    font-size: 14px;
  }
  .textoVista {
    font-size: 13px;
  }
}
@media screen and (max-height: 880px) {
  .textoProyecto{
    font-size: 10px;
  }
  .textoVista {
    font-size: 8px;
  }
}


.hide-validation-error .md-errors-spacer 
  { display: none; }

.controls {
    margin-top: 10px;
    border: 1px solid transparent;
    border-radius: 2px 2px 2px 2px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    height: 32px;
    outline: none;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}

#pac-input {
    background-color: #fff;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 300;
    margin-left: 12px;
    padding: 0 11px 0 13px;
    text-overflow: ellipsis;
    width: 300px;
}

#pac-input:focus {
    border-color: #4d90fe;
}

.pac-container {
    font-family: Roboto;
}

.filter-activo {
    background-color: #95D17B !important;
}


.filter-inactivo {
    background-color: #adadad !important;
}

.md-select-value > span:not(.md-select-icon) .md-text {
    display: contents !important;
}

.hide-validation-error .md-errors-spacer {
     display: none; 
}