.bottomsheet {
    margin-left: 400px;
    padding: 0;
    background-color: transparent;
    z-index: 9999;
    -webkit-transform: none;
    border: 0;
    bottom: 14px;
}

.button-filter {
    z-index:9999;
    width: 130px;
}

.buttons-no-bottom-margin{
    margin-bottom: 0px;
}

.label-categoria {
    text-align:center;
    margin: 0;
}

.img-categoria {
    height:30px;
    width: 30px;
    margin: 10px;
}

.filters-bakground {
        border: 1px solid #FFFFFF;
        border-width: 0px 4px 0px 0px;
        height:300px;
        background-color: #61abeb !important;
        color: #FFFFFF !important;
     }

.btnFilter-activo {
    background-color: #95D17B !important;
}

.btnFilter-inactivo {
    background-color: #adadad !important;
}

.header-filtro {
    padding-left: 20px;
    padding-top: 8px;
    padding-bottom: 3px;
    text-align: left;
    background-color: #0B4774;
    font-family: Avenir;
    font-size: 25px;
}

.content-filtro {
    padding: 5px;
    padding-left: 20px;
    padding-right: 20px;
    overflow: auto;
}

md-checkbox.md-checked ._md-icon {
    background-color: white;
}

md-checkbox.md-default-theme.md-checked .md-icon, md-checkbox.md-checked .md-icon {
    background-color: #95D17B;
}

md-checkbox.md-default-theme:not(.md-checked) .md-icon, md-checkbox:not(.md-checked) .md-icon {
    border-color: white !important;
}

md-icon.md-default-theme, md-icon {
    color: #FFFFFF;
}

.md-default-theme.md-hue-2 .md-datepicker-input, .md-hue-2 .md-datepicker-input {
    color: #FFFFFF;
}

.md-default-theme .md-datepicker-triangle-button .md-datepicker-expand-triangle, .md-datepicker-triangle-button .md-datepicker-expand-triangle {
    border-top-color: #FFFFFF;
}

md-select.md-default-theme .md-select-value.md-select-placeholder, md-select .md-select-value.md-select-placeholder {
    color: #FFFFFF;
}

md-select.md-default-theme .md-select-value.md-select-placeholder, md-select .md-select-value.md-select-placeholder {
    color: #FFFFFF !important;
}

md-slider.md-default-theme .md-track.md-track-fill, md-slider .md-track.md-track-fill {
    background-color: #FFFFFF;
}

md-slider.md-default-theme .md-thumb:after, md-slider .md-thumb:after {
    background-color: #FFFFFF;
}

/*md-tabs.md-default-theme .md-tab, md-tabs .md-tab {
    color: #FFFFFF !important;
}

md-tabs.md-default-theme md-ink-bar, md-tabs md-ink-bar {
    color: #FFFFFF;
    background-color: #FFFFFF;
}*/

@keyframes hide {
    from {margin-bottom: 0px;}
    to {margin-bottom: -350px;}
}

@keyframes show {
    from {margin-bottom: -350px;}
    to {margin-bottom: 0px;}
}

.filter-hide {
    -webkit-animation-name: hide;  /* Safari 4.0 - 8.0 */
    -webkit-animation-duration: .2s;  /* Safari 4.0 - 8.0 */    
    -webkit-animation-fill-mode: forwards; /* Safari 4.0 - 8.0 */
    animation-name: hide;
    -webkit-animation-timing-function: cubic-bezier(.25, .8, .25, 1);;
    animation-duration: .2s;    
    animation-timing-function: cubic-bezier(.25, .8, .25, 1);;
    animation-fill-mode: forwards;
}

.filter-show {
    -webkit-animation-name: show;  /* Safari 4.0 - 8.0 */
    -webkit-animation-duration: .2s;  /* Safari 4.0 - 8.0 */    
    -webkit-animation-fill-mode: forwards; /* Safari 4.0 - 8.0 */
    -webkit-animation-timing-function: cubic-bezier(.25, .8, .25, 1);;
    animation-name: show;
    animation-duration: .2s;    
    animation-timing-function: cubic-bezier(.25, .8, .25, 1);;
    animation-fill-mode: forwards;
}

.filter-init {
    margin-bottom: -350px;
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}
::-webkit-scrollbar-thumb:active {
  background: #000000;
}
::-webkit-scrollbar-track {
  background: #666666;
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
  background: #666666;
}
::-webkit-scrollbar-track:active {
  background: #333333;
}
::-webkit-scrollbar-corner {
  background: transparent;
}

.hide-validation-error .md-errors-spacer {
     display: none; 
}

md-input-container:not(.md-input-invalid).md-input-focused .md-input  {
    border-color: red
}

md-input-container:not(.md-input-invalid).md-input-focused label {
    color: red
}

md-switch.md-default-theme.md-checked .md-bar, md-switch.md-checked .md-bar {
    background-color: rgba(3, 229, 41, 0.5) !important;
}

md-switch.md-default-theme.md-checked .md-thumb, md-switch.md-checked .md-thumb {
    background-color: rgb(3, 229, 52) !important;
}

md-radio-button.md-default-theme .md-off, md-radio-button .md-off {
     border-color: #FFFFFF; 
}

md-radio-button.md-default-theme .md-on, md-radio-button .md-on {
    background-color: rgba(255, 255, 255, 0.87);
}

md-radio-button.md-default-theme.md-checked .md-off, md-radio-button.md-checked .md-off {
     border-color: rgba(255, 255, 255, 0.87); 
}

md-radio-button.md-default-theme:not([disabled]).md-primary .md-on, md-radio-button:not([disabled]).md-primary .md-on, md-radio-button.md-default-theme:not([disabled]) .md-primary .md-on, md-radio-button:not([disabled]) .md-primary .md-on, md-radio-group.md-default-theme:not([disabled]).md-primary .md-on, md-radio-group:not([disabled]).md-primary .md-on, md-radio-group.md-default-theme:not([disabled]) .md-primary .md-on, md-radio-group:not([disabled]) .md-primary .md-on {
    background-color: rgba(255, 255, 255, 0.87);
}

md-radio-button.md-default-theme:not([disabled]).md-primary.md-checked .md-off, md-radio-button:not([disabled]).md-primary.md-checked .md-off, md-radio-button.md-default-theme:not([disabled]) .md-primary.md-checked .md-off, md-radio-button:not([disabled]) .md-primary.md-checked .md-off, md-radio-button.md-default-theme:not([disabled]).md-primary .md-checked .md-off, md-radio-button:not([disabled]).md-primary .md-checked .md-off, md-radio-button.md-default-theme:not([disabled]) .md-primary .md-checked .md-off, md-radio-button:not([disabled]) .md-primary .md-checked .md-off, md-radio-group.md-default-theme:not([disabled]).md-primary.md-checked .md-off, md-radio-group:not([disabled]).md-primary.md-checked .md-off, md-radio-group.md-default-theme:not([disabled]) .md-primary.md-checked .md-off, md-radio-group:not([disabled]) .md-primary.md-checked .md-off, md-radio-group.md-default-theme:not([disabled]).md-primary .md-checked .md-off, md-radio-group:not([disabled]).md-primary .md-checked .md-off, md-radio-group.md-default-theme:not([disabled]) .md-primary .md-checked .md-off, md-radio-group:not([disabled]) .md-primary .md-checked .md-off {
    border-color: rgba(255, 255, 255, 0.87);
}


md-radio-button.md-tealTheme-theme .md-on {
    background-color: rgba(255, 255, 255, 0.87);
}

md-icon.md-tealTheme-theme {
    color: rgb(255, 255, 255);
}

.medios-limpiar-btn{
    min-height: 34px;
}

    