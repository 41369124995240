#atributo{
    dialog {
        md-dialog {
            .contact-dialog_atributo {
                max-width: 1100px;
                width: 1075px;
                md-toolbar {
                    .title {
                        font-size: 17px;
                    }
                    .md-toolbar-tools-middle {
                        height: 144px;
                        min-height: 144px;
                        max-height: 144px;
                        .contact-avatar {
                            margin: 0;
                        }
                        .contact-name {
                            font-size: 24px;
                            margin: 8px 0;
                        }
                    }
                    .md-toolbar-tools-bottom {
                        md-menu {
                            margin: 0;
                        }
                    }
                }
                md-dialog-content {
                    display: block;
                    position: relative;
                    .birthday-input {
                        display: flex;
                        flex-direction: row;
                        width: 100%;
                        margin: 0;
                        padding: 0 0 32px 0;
                        .md-button {
                            margin: 0;
                            padding: 0;
                            width: 24px;
                            height: 24px;
                            md-icon {
                                color: rgba(0, 0, 0, 0.87);
                            }
                        }
                        .md-datepicker-input-container {
                            display: flex;
                            flex: 1;
                        }
                    }
                    .textarea-wrapper {
                        md-input-container {
                            margin-top: 0;
                        }
                        md-icon {
                            color: rgba(0, 0, 0, 0.87);
                            margin: 0 8px 0 0;
                        }
                    }
                }
                md-dialog-actions {
                    position: relative;
                    overflow-y: hidden;
                    overflow-x: auto;
                    justify-content: space-between;
                    background-color: rgba(0, 0, 0, 0.03);
                    border-top: 1px solid rgba(0, 0, 0, 0.12);
                }
            }
        }
        
            
    }
}
/**
 * The dndDraggingSource class will be applied to
 * the source element of a drag operation. It makes
 * sense to hide it to give the user the feeling
 * that he's actually moving it.
 */

ul[dnd-list] .dndDraggingSource {
    display: none;
}


/**
 * An element with .dndPlaceholder class will be
 * added to the dnd-list while the user is dragging
 * over it.
 */

ul[dnd-list] .dndPlaceholder {
    background-color: #ddd;
    display: block;
    min-height: 42px;
}

ul[dnd-list] li {
    background-color: #fff;
    border: 1px solid #ddd;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    display: block;
    padding: 10px 15px;
    margin-bottom: -1px;
}


/**
 * Show selected elements in green
 */

ul[dnd-list] li.selected {
    background-color: #dff0d8;
    color: #3c763d;
}