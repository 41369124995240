.md-datepicker-button md-icon,
.md-hue-2 .md-datepicker-open .md-datepicker-calendar-icon {
  color: #1faeeb;
}
.filtros-barra-container{
  .md-datepicker-input-container{
    .md-datepicker-input {
      color: black;
    }
  }
}

.md-default-theme
  .md-datepicker-triangle-button
  .md-datepicker-expand-triangle {
  border-top-color: #1faeeb;
}

.filtros-barra-container {
  display: flex;
  flex-direction: row;
  padding-left: 5px;
  padding-bottom: 5px;
  flex-wrap: wrap;
  margin-left: 70px;
  margin-top: 25px;
}

.filtro-municipios-top-reportes{
  display: flex;
  flex-direction: row;
  padding-left: 5px;
  padding-bottom: 5px;
  flex-wrap: wrap;
  margin-left: 70px;
  margin-top: 25px;
  align-items: center;
  label{
    margin-right: 10px;
  }
}

.filtro-estados-dashboard{
  display: flex;
  flex-direction: row;
  padding-left: 5px;
  margin-left: 10px;
  padding-bottom: 5px;
  flex-wrap: wrap;
  align-items: center;
  label{
    margin-right: 10px;
  }
  md-select{
    margin: 0px;
  }
}

.grafica-barras {
  min-width: 310px;
  width: 90%;
  height: 400px;
  margin: 0 auto;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 8px;
}

.grafica-pastel {
    min-width: 310px;
    width: 50%;
    height: 400px;
    margin: 0 auto;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 8px;
  }
