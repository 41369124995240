.loading {
    z-index: 9998;
    background-color: #19447A
}

.gmap-outer-container, ui-gmap-google-map, .angular-google-map, .angular-google-map-container {
    height: 100%;
}

.total-reportes {
    background-color: #004675;
    z-index: 9999;
}

.iconografiaZoom {
    margin-right: 15px;
}

.total-reportes-label {
    color: #E4E4E6;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
}

.fechas-label {
    background-color: #1FAEEB;
    z-index: 9999;
}

.inputclass {
    padding: 10px;
    border: solid 1px grey;
    transition: box-shadow 0.3s, border 0.3s;
}

.inputclass:focus,
.inputclass.focus {
    border: solid 1px #707070;
    box-shadow: 0 0 5px 1px #969696;
}

.btn-activo {
    background-color: #95D17B !important;
}

.btn-usuario {
    background-color: #0094CC;
}

.chart-background {
    background-color: #E7F1F9;
    border-radius: 25px;
    padding: 20px;
}

.nvtooltip table {
    background-color: #FFFFFF;
}

.calendar-heatmap .heatmap-tooltip {
    top: 40% !important;
}

.atributos {
    background-color: rgb(0, 70, 117);
    float: left;
    margin-right: 5px;
    padding: 5px;
    border-radius: 15px;
    margin-bottom: 2px;
}

.cluster>div {
    color: white !important;
}

md-radio-button.md-tealTheme-theme .md-off {
    border-color: white !important;
}

md-radio-button.md-tealTheme-theme:not([disabled]).md-primary .md-on,
md-radio-button.md-tealTheme-theme:not([disabled]) .md-primary .md-on,
md-radio-group.md-tealTheme-theme:not([disabled]).md-primary .md-on,
md-radio-group.md-tealTheme-theme:not([disabled]) .md-primary .md-on {
    background-color: white;
}

.images-geo {
    width: 20%;
    height: 30%;
}

md-progress-circular.md-default-theme path,
md-progress-circular path {
    stroke: #1FAEEB !important;
}

.loading-label {
    margin-top: 10px;
    text-align: center;
    color: #dddddd;
    font-weight: 500;
    font-size: 20px;
    font-family: Avenir;
}